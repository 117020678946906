<template>
     <KCourse loader-class="MBcont" course-id="0" title="Чек-листы по эмоциям" :items="items">
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck3/2.jpg')" alt="" width="100%"/></div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceCheck3/1.jpg')" alt="" width="100%"/></div>
    </KCourse>
</template>

<script>
    import KCourse from "../../../Widgets/KCourse";
    import KImage from "../../../Widgets/KImage";
    import Paragrath from "./Paragraph"
    import {getEmailFaceCheck} from "@/service/api";
    import {getEmailFaceCheck3} from "@/service/api";
    export default {
        name: "MBCourseHead",
        components: {
            KCourse,
            KImage,
            Paragrath
        },
      created() {
        getEmailFaceCheck3()
      },
        data() {
        return {
            items: [
                { title: "База лица", url:"/mycourses/face-emotions/1"},
                { title: "Улыбка", url:"/mycourses/face-emotions/2"},
                { title: "Обида", url:"/mycourses/face-emotions/3"},
                { title: "Удивление", url:"/mycourses/face-emotions/4"},
            ],
        }
        }
    }
</script>

<style scoped>
    .KImage{ 
        width: 80%;
        margin: 0 auto 50px auto;
        display: block;
    }    .KImage.mobile, .KImage.laptop{
             width:100%
         }
    
    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>